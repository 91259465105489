import { observable, action, configure } from 'mobx';

configure({ enforceActions: 'observed' });

class messageStore {
  @observable message = null;

  @action.bound newMessage(msg) {
    this.message = msg;
  }

  @action.bound clearMessage() {
    this.message = null;
  }
}

export default new messageStore();
