import React, { Component } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import Search from './Search';
import Prices from './Dashboard';
import Tdsp from './Tdsp';
import Promos from './Promos/Promos';
import DnpRnpTable from './dnpRnp';
import MemberDetails from './members/index';
import Logout from './user/Logout';

const Container = styled.div`
  display: flex;
`;

const Main = styled.div`
  flex-grow: 1;
`;

const Sidebar = styled.div`
  padding: 15px;
  min-width: 100px;
  height: 100vh;
  background: #e6cccc;
`;

const SidebarItem = styled(NavLink)`
  display: block;
  padding: 5px;
`;

class Layout extends Component {
  render() {
    return (
      <Container>
        <Sidebar>
          <SidebarItem to="/dashboard">Dashboard</SidebarItem>
          <SidebarItem to="/search">Search</SidebarItem>
          <SidebarItem to="/tdsp">Tdsp</SidebarItem>
          <SidebarItem to="/promos">Promos</SidebarItem>
          <SidebarItem to="/dnpRnp">Dnp Rnp</SidebarItem>
          <SidebarItem to="/logout">Logout</SidebarItem>
        </Sidebar>
        <Main>
          <Switch>
            <Route path="/dashboard/" component={Prices} />
            <Route path="/search/" component={Search} />
            <Route path="/tdsp/" component={Tdsp} />
            <Route path="/promos/" component={Promos} />
            <Route path="/dnpRnp/" component={DnpRnpTable} />
            <Route path="/member/:id" component={MemberDetails} />
            <Route path="/logout/" component={Logout} />
          </Switch>
        </Main>
      </Container>
    );
  }
}

export default Layout;
