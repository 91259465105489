import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

const Logout = inject('userStore')(
  observer(props => {
    props.userStore.logout();
    return <Redirect to="/" />;
  })
);

export default Logout;
