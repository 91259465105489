import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';

@inject('promoStore')
@observer
class Promos extends Component {
  componentDidMount() {
    this.props.promoStore.fetchPromos();
  }
  componentWillUnmount() {
    this.props.promoStore.clearPromos();
  }

  render() {
    const {
      promoStore: { promos },
    } = this.props;
    return (
      <Page>
        <ul>
          {promos &&
            promos.map(p => (
              <li key={p.code}>
                <Link to={`/promos/promoDetail/${p.code}`}>
                  {p.description}
                </Link>
              </li>
            ))}
        </ul>
        <Link to={`/promos/promoDetail/new`}>New</Link>
      </Page>
    );
  }
}

export default Promos;
