import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { pathOr } from 'ramda';
import Page from 'components/Page';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

const List = styled.ul`
  list-decoration: none;
`;

const ListItem = styled.li`
  padding: 5px, 0;
`;

const ListItemTitle = styled.span`
  font-weight: bold;
  margin-right: 15px;
`;

const ListItemValue = styled.span``;

const Caption = styled.h2`
  font-size: 1.5rem !important;
`;

const ActionButton = styled(Button)`
  margin-right: 10px;
`;

const MemberDetails = inject('memberStore', 'dnpRnpStore')(
  observer(props => {
    const {
      memberStore: { fetchMember, clearMember, member, meter },
      dnpRnpStore: { memberTableData, dnpRequest, rnpRequest, loading },
      match: {
        params: { id: memberID },
      },
    } = props;

    useEffect(() => {
      fetchMember(memberID);

      return clearMember();
    }, [clearMember, fetchMember, memberID]);

    return (
      <Page>
        <h2>Details</h2>
        <h3>Member</h3>
        <List>
          <ListItem key="firstName">
            <ListItemTitle>First Name:</ListItemTitle>
            <ListItemValue>{member.first_name}</ListItemValue>
          </ListItem>
          <ListItem key="lastName">
            <ListItemTitle>Last Name:</ListItemTitle>
            <ListItemValue>{member.last_name}</ListItemValue>
          </ListItem>
          <ListItem key="memberID">
            <ListItemTitle>Member ID:</ListItemTitle>
            <ListItemValue>{member.memberID}</ListItemValue>
          </ListItem>
          <ListItem key="userID">
            <ListItemTitle>User ID:</ListItemTitle>
            <ListItemValue>{member.userID}</ListItemValue>
          </ListItem>
          <ListItem key="balance">
            <ListItemTitle>Current Balance:</ListItemTitle>
            <ListItemValue>
              ${member.current_balance_dollars || 'N/A'}
            </ListItemValue>
          </ListItem>
        </List>
        <h3>Meter</h3>
        <List>
          <ListItem key="meterStatus">
            <ListItemTitle>Status:</ListItemTitle>
            <ListItemValue>
              {pathOr('missing', ['status', 'status'], meter)}
            </ListItemValue>
          </ListItem>
        </List>
        <div>
          <ActionButton
            appearance="primary"
            onClick={() => {
              dnpRequest(meter.ID);
            }}
          >
            DNP
          </ActionButton>
          <ActionButton
            appearance="primary"
            onClick={() => {
              rnpRequest(meter.ID);
            }}
          >
            RNP
          </ActionButton>
        </div>
        <DynamicTable
          caption={<Caption>Dnp History</Caption>}
          head={memberTableData.head}
          rows={memberTableData.rows}
          rowsPerPage={10}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={loading}
          onSort={() => console.log('onSort')}
          onSetPage={() => console.log('onSetPage')}
        />
      </Page>
    );
  })
);

export default MemberDetails;
