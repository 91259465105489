import pricesStore from './pricesStore';
import userStore from './userStore';
import searchStore from './searchStore';
import tdspStore from './tdspStore';
import promoStore from './promoStore';
import dnpRnpStore from './dnpRnpStore';
import memberStore from './memberStore';
import messageStore from './messageStore';

export default {
  pricesStore,
  userStore,
  searchStore,
  tdspStore,
  promoStore,
  dnpRnpStore,
  memberStore,
  messageStore,
};
