import React from 'react';
import { Provider } from 'mobx-react';
import stores from './stores';
import { Router, Switch } from 'react-router-dom';
import history from './js/history';
import Auth from './containers';

function App() {
  return (
    <Provider {...stores}>
      <Router history={history}>
        <Switch>
          <Auth />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
