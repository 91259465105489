import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from 'containers/ProtectedRoute.js';
import Layout from 'containers/Layout';
import Login from 'containers/Login';

@inject('userStore')
@observer
class Auth extends Component {
  componentDidMount() {
    this.props.userStore.loginWithStorageCredentials();
  }

  render() {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <ProtectedRoute path="/" component={Layout} />
      </Switch>
    );
  }
}

export default Auth;
