import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';
import DynamicTable from '@atlaskit/dynamic-table';

@inject('dnpRnpStore')
@observer
class DnpRnpTable extends Component {
  componentDidMount() {
    this.props.dnpRnpStore.fetchPendingTransactions();
  }

  render() {
    const {
      dnpRnpStore: { loading, tableData },
    } = this.props;

    return (
      <Page>
        <DynamicTable
          caption="Pending Transactions"
          head={tableData.head}
          rows={tableData.rows}
          rowsPerPage={10}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={loading}
          defaultSortKey="member"
          defaultSortOrder="ASC"
          onSort={() => console.log('onSort')}
          onSetPage={() => console.log('onSetPage')}
        />
      </Page>
    );
  }
}

export default DnpRnpTable;
