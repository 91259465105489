import { observable, action, flow, configure } from 'mobx';
import messageStore from './messageStore';
import * as API from '../api';

configure({ enforceActions: 'observed' });

const defaultPromo = {
  code: '',
  name: '',
  description: '',
  is_active: false,
  unit: '',
  value: '',
};
// export const PROMO_GET = "/promo/get";
// export const PROMO_GET_ALL_URL = "/promo/get_all";
// export const PROMO_CREATE_URL = "/promo/create";
// export const PROMO_UPDATE_URL = "/promo/update";

class promoStore {
  @observable promos = [];
  @observable promoDetail = defaultPromo;
  @observable pending = false;
  @observable pendingDetails = false;

  @action.bound clearPromos() {
    this.promos = [];
  }

  createPromo = flow(function*(promo) {
    yield console.log('create', promo);
  }).bind(this);

  updatePromo = flow(function*(promo) {
    yield console.log('update', promo);
  }).bind(this);

  fetchPromos = flow(function*() {
    this.pending = true;
    try {
      const { promos } = yield API.fetchAllPromos();
      this.promos = promos;
      this.pending = false;
    } catch (err) {
      this.pending = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);

  fetchPromoDetails = flow(function*(code) {
    this.promoDetail = defaultPromo;
    this.pendingDetails = true;
    this.promoDtail = defaultPromo;
    if (code !== 'new') {
      console.log('called');
      try {
        const { promo } = yield API.fetchPromo({ code });
        this.promoDetail = promo;
      } catch (err) {
        messageStore.newMessage({ type: 'Error', body: err.message });
      }
    }
    this.pendingDetails = false;
  }).bind(this);
}

export default new promoStore();
