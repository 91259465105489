import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PromoDetails from './PromoDetails';
import PromoSearch from './PromoSearch.js';
import Page from 'components/Page';

const Promos = () => {
  const { path } = useRouteMatch();
  return (
    <Page>
      <Switch>
        <Route path={`${path}promoDetail/:id`} component={PromoDetails} />
        <Route path={`${path}promoDetail/new`} component={PromoDetails} />
        <Route path={path} component={PromoSearch} />
      </Switch>
    </Page>
  );
};

export default Promos;
