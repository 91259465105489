import { observable, action, flow, configure } from 'mobx';
import * as API from '../api';

configure({ enforceActions: 'observed' });

class tdspStore {
  @observable searchResults = [];

  @action.bound clearResults() {
    this.searchResults = [];
  }

  tdspSearch = flow(function*({ memberID }) {
    try {
      const {
        data: { rows },
      } = yield API.getTdspInvoices({ memberID });
      this.searchResults = rows;
    } catch (err) {
      console.log(err);
    }
  }).bind(this);
}

export default new tdspStore();
