import { observable, action, runInAction, configure } from 'mobx';
import messageStore from './messageStore';
import * as api from '../api';

configure({ enforceActions: 'observed' });

const initialState = {
  id: '',
  isAuthenticated: false,
};

class userStore {
  @observable user = initialState;

  @action.bound logout() {
    localStorage.clear();
    this.user = initialState;
  }

  @action.bound loginWithStorageCredentials() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.user = {
        id: userId,
        isAuthenticated: true,
      };
    }
  }

  @action.bound loginUser({ email, password }) {
    api
      .login({ email, password })
      .then(res => {
        const { userID: userId, access_token, refresh_token } = res;
        const user = {
          id: userId,
          isAuthenticated: true,
        };
        localStorage.setItem('access_token', `"${access_token}"`);
        localStorage.setItem('refresh_token', `"${refresh_token}"`);
        localStorage.setItem('userId', userId);
        runInAction(() => {
          this.user = user;
        });
      })
      .catch(err => {
        messageStore.newMessage({ type: 'Error', body: err.message });
      });
  }
}

export default new userStore();

// @action.bound redirectLogin() {
// 	console.log('redirect login');
// 	this.redirect = true;
// }

// @action.bound
// async asyncLogin(fields) {
// 	const { email, password } = fields;
// 	const { data: {userID, access_token, refresh_token } } = await api.login({email, password})
// 	runInAction(() => {
// 	    this.user.id = userID;
// 	    this.user.authenticated = true;
// 	})
// 	console.log('async call complete')
// }

// login = flow(function*(fields) {
// 	const { email, password } = fields;
// 	try {
// 	    const { data: {userID, access_token, refresh_token } } = yield api.login({email, password})
// 	    yield this.user = {
// 		id: userID,
// 		authenticated: true,
// 	    };
// 	} catch (err) {
// 	    //TODO handle errors
// 	    console.log('handle me: ', err)
// 	}
// }).bind(this)
