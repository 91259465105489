import { observable, action, flow, configure } from 'mobx';
import dnpRnpStore from './dnpRnpStore';
import * as API from '../api';

configure({ enforceActions: 'observed' });

class memberStore {
  @observable member = {
    memberID: null,
    meterID: null,
    ID: null,
    first_name: null,
  };

  @observable meter = {};

  @observable fetching = false;

  @action.bound clearMember() {
    this.member = {};
    this.meter = {};
  }

  fetchMember = flow(function*(memberID) {
    this.fetching = true;
    try {
      const member = yield API.getMember({ memberID });
      const meters = yield API.getMeter({ memberID, include_inactive: false });
      this.member = member;
      this.meter = meters.meters[0];
      yield dnpRnpStore.search(this.meter.ID);
      this.fetching = false;
    } catch (err) {
      this.fetching = false;
    }
  }).bind(this);
}

export default new memberStore();
