import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Form, { Field, FormHeader } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Toggle from '@atlaskit/toggle';
import Select from '@atlaskit/select';
import Page from 'components/Page';

@inject('promoStore')
@observer
class PromoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true,
    };
  }

  componentDidMount() {
    const {
      promoStore: { fetchPromoDetails },
      match: {
        params: { id },
      },
    } = this.props;
    fetchPromoDetails(id);
  }

  handleSubmit = d => {
    const {
      promoStore: { updatePromo, createPromo },
      match: {
        params: { id },
      },
    } = this.props;
    if (id === 'new') {
      createPromo(d);
    } else {
      updatePromo(d);
    }
  };

  render() {
    const {
      promoStore: { promoDetail: promo },
    } = this.props;
    const { isEditing } = this.state;
    const disabled = !isEditing;
    return (
      <Page>
        <Form onSubmit={this.handleSubmit}>
          {({ formProps }) => (
            <form {...formProps}>
              <FormHeader title="Promo form" />
              <div>
                <span>Edit: </span>
                <Toggle
                  isDefaultChecked={true}
                  lable="Edit"
                  isChecked={disabled}
                  onChange={() => this.setState({ isEditing: !isEditing })}
                />
              </div>
              <Field
                name="name"
                defaultValue={promo.name}
                label="Name"
                isDisabled={disabled}
                isRequired
              >
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field
                name="code"
                defaultValue={promo.code}
                label="Code"
                isRequired
              >
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field
                name="description"
                defaultValue={promo.description}
                label="Description"
                isRequired
              >
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field
                name="isActive"
                defaultValue={promo.is_active}
                label="Is active"
                isDisabled={disabled}
                isRequired
              >
                {({ fieldProps }) => (
                  <Select
                    options={[
                      { label: 'Active', value: 'true' },
                      { label: 'Disabled', value: 'false' },
                    ]}
                    placeholder="Is active"
                    {...fieldProps}
                  />
                )}
              </Field>
              <Field
                name="unintAndValue"
                label="Unint and Value"
                isDisabled={disabled}
                isRequired
              >
                {({ fieldProps }) => (
                  <Select
                    options={[
                      {
                        label: 'Months',
                        options: [
                          { label: '1 month', value: '1', unit: 'month' },
                          { label: '2 months', value: '2', unit: 'month' },
                          { label: '3 months', value: '3', unit: 'month' },
                          { label: '4 months', value: '4', unit: 'month' },
                        ],
                      },
                      {
                        label: 'Dollars',
                        options: [
                          { label: '$25 dollars', value: '25', unit: 'dollar' },
                          { label: '$50 dollars', value: '50', unit: 'dollar' },
                        ],
                      },
                    ]}
                    {...fieldProps}
                  />
                )}
              </Field>
              {isEditing && (
                <Button type="submit" appearance="primary">
                  Submit
                </Button>
              )}
            </form>
          )}
        </Form>
      </Page>
    );
  }
}

export default PromoDetails;
