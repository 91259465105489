import { observable, action, flow, configure } from 'mobx';
import messageStore from './messageStore';
import * as API from '../api';

configure({ enforceActions: 'observed' });

class searchStore {
  @observable results = [];
  @observable fetching = false;

  @action.bound clearResults() {
    this.results = [];
  }

  searchByEsiID = flow(function*(esiID) {
    this.clearResults();
    try {
      this.fetching = true;
      const { meters } = yield API.getMeter({ meterID: esiID });
      const members = [];
      while (meters.length) {
        const meter = meters.pop();
        const member = yield API.getMember({ memberID: meter.memberID });
        member.meters = [meter];
        members.push(member);
      }
      this.fetching = false;
      this.results = members;
    } catch (err) {
      this.fetching = false;
      console.log(err);
    }
  }).bind(this);

  searchByUserID = flow(function*(userID) {
    this.clearResults();
    try {
      this.fetching = true;
      const { Members: members } = yield API.searchMembersAll(userID);
      const membersWithMeter = [];
      while (members.length) {
        const member = members.pop();
        const meters = yield API.getMeter({ memberID: member.memberID });
        member.meters = meters.meters;
        membersWithMeter.push(member);
      }
      this.fetching = false;
      this.results = membersWithMeter;
    } catch (err) {
      this.fetching = false;
      messageStore.newMessage({ type: 'Error', body: err });
    }
  }).bind(this);

  searchByMemberID = flow(function*(memberID) {
    this.clearResults();
    try {
      this.fetching = true;
      const member = yield API.searchMembers(memberID);
      const meter = yield API.getMeter({ memberID: member.memberID });
      this.fetching = false;
      this.results = [meter];
    } catch (err) {
      this.fetching = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);
}

export default new searchStore();
