import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

@inject('userStore')
@observer
class ProtectedRoute extends Component {
  render() {
    const { component: Component, userStore, ...rest } = this.props;
    const {
      location: { pathname },
    } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          userStore.user.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location, redirect: pathname },
              }}
            />
          )
        }
      />
    );
  }
}

export default ProtectedRoute;
