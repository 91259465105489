import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Page from 'components/Page';

@inject('tdspStore')
@observer
class TDSP extends Component {
  componentWillUnmount() {
    this.props.tdspStore.clearResults();
  }

  render() {
    const {
      tdspStore: { tdspSearch, searchResults },
    } = this.props;
    return (
      <Page>
        <Form onSubmit={tdspSearch}>
          {({ formProps }) => (
            <form {...formProps}>
              <Field
                name="memberID"
                defaultValue=""
                label="Member Id"
                autoComplete="off"
              >
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Button type="submit" appearance="primary">
                Search
              </Button>
            </form>
          )}
        </Form>
        <ul>
          {searchResults.map(i => (
            <li key={i.invoiceID}>
              MemberID: {i.memberID}, InvoiceID: {i.invoiceID}, Invoice Date:{' '}
              {i.invoice_date}
            </li>
          ))}
        </ul>
      </Page>
    );
  }
}

export default TDSP;
