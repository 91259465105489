import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

const Container = styled.div`
  padding: 40px 80px;
  position: relative;
`;

const Msg = styled.div`
  position: absolute;
  background: #f5717c;
  border: 2px solid red;
  border-radius: 2px;
  padding: 15px 20px;
  min-width: 200px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;
const MsgHeader = styled.span``;
const MsgBody = styled.span``;

const Message = ({ type, body }) => (
  <Msg type={type}>
    <MsgHeader>{type}: </MsgHeader>
    <MsgBody>{body}</MsgBody>
  </Msg>
);

const Page = inject('messageStore')(
  observer(props => {
    let {
      messageStore: { message, clearMessage },
    } = props;

    if (message) {
      setTimeout(() => clearMessage(), 3000);
    }

    return (
      <Container>
        {message && <Message body={message.body} type={message.type} />}
        <div>{props.children}</div>
      </Container>
    );
  })
);

export default Page;
