import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Page from 'components/Page';

const ActionButton = styled(Button)`
  margin-top: 15px;
`;

@inject('userStore')
@observer
class Login extends Component {
  render() {
    const {
      userStore: { user, loginUser },
    } = this.props;
    const {
      location: {
        state: {
          from: { pathname },
        },
      },
    } = this.props;
    // const { location: {pathname}} = this.props;
    // const pathname = false;
    // console.log(this.props, user.isAuthenticated)
    const location = pathname || '/';

    return user.isAuthenticated ? (
      <Redirect to={location} />
    ) : (
      <Page>
        <div>Login</div>
        <br />
        <Form onSubmit={loginUser}>
          {({ formProps }) => (
            <form {...formProps}>
              <Field
                name="email"
                defaultValue=""
                label="Email"
                autoComplete="off"
              >
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field
                name="password"
                defaultValue=""
                label="Password"
                autoComplete="off"
              >
                {({ fieldProps }) => (
                  <TextField type="password" {...fieldProps} />
                )}
              </Field>
              <ActionButton type="submit" appearance="primary">
                Submit
              </ActionButton>
            </form>
          )}
        </Form>
      </Page>
    );
  }
}

export default Login;

// componentDidUpdate(prevProps) {
// 	const { userStore: { user: { authenticated: prevAuth }}} = prevProps;
// 	const { userStore: { redirectLogin, redirect }} = this.props;

// 	if (!redirect && auth) {
// 	    console.log('calling redirectLogin')
// 	    redirectLogin();
// 	}
// }

// async loginUser({email, password}) {
// 	try {
// 	    const { data: {userID, access_token, refresh_token } } = await api.login({email, password})
// 	    this.props.userStore.loginUser({userID});
// 	} catch (err) {
// 	    console.log(err);
// 	}
// }

// loginUser({email, password}) {
// 	try {
// 	    api.login({email, password})
// 		.then( res => {
// 		    const { data: {userID, access_token, refresh_token } } = res
// 		    this.props.userStore.loginUser({userID});
// 		})
// 	} catch (err) {
// 	    console.log(err);
// 	}
// }

// handleSubmit = e => {
// 	e.preventDefault();
// 	this.props.userStore.auth()
// }

// @inject('userStore')
// @observer
// class Login extends Component {
//     handleSubmit = e => {
// 	e.preventDefault();
// 	// console.log(fields)
// 	this.props.userStore.auth()
//     }
//     render() {
// 	const { userStore: {isAuthenticated, authenticate, login} } = this.props;
// 	return  !isAuthenticated ? (
// 	    <Page>
// 		<div>Login</div>
// 		<br/>
// 		<form onSubmit={login}>
// 		<button type="submit">Submit</button>
// 		</form>
// 	    </Page>
// 	) : (<Redirect to='/public'/>)
//     }
// }

// export default Login;
