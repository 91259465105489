import { assoc, reduce, keys } from 'ramda';

export const snakeToCamel = str =>
  str.replace(/(_\w)/g, m => m[1].toUpperCase());
export const camelToSnake = str =>
  str
    .replace(/\.?([A-Z]+)/g, (x, y) => '_' + y.toLowerCase())
    .replace(/^_/, '');

const processObject = (obj, fn) =>
  reduce((acc, key) => assoc(fn(key), obj[key], acc), {}, keys(obj));

export const prepareObjectForCall = obj => processObject(obj, camelToSnake);
export const processObjectFromApi = obj => processObject(obj, snakeToCamel);

export const parseApiList = arr => arr.map(obj => processObjectFromApi(obj));
