import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Page from 'components/Page';
import Spinner from '@atlaskit/spinner';

const Container = styled.div`
  max-width: 600px;
`;

const ActionButton = styled(Button)`
  margin-top: 15px;
`;

@inject('searchStore')
@observer
class Search extends Component {
  state = {
    activeSearch: 'member', // member, esi, user, address
  };

  componentWillUnmount() {
    this.props.searchStore.clearResults();
  }

  search = fields => {
    const {
      searchStore: { searchByUserID, searchByMemberID, searchByEsiID },
    } = this.props;

    switch (this.state.activeSearch) {
      case 'user':
        searchByUserID(fields.userID);
        break;
      case 'esi':
        searchByEsiID(fields.esiID);
        break;
      case 'member':
        searchByMemberID(fields.memberID);
        break;
      default:
        console.log('default');
    }
  };

  render() {
    const {
      searchStore: { results, fetching },
    } = this.props;
    return (
      <Page>
        <Container>
          <Form onSubmit={this.search}>
            {({ formProps }) => (
              <form {...formProps}>
                <Field name="memberID" defaultValue="" label="Member Id">
                  {({ fieldProps }) => (
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      onClick={() => {
                        this.setState({ activeSearch: 'member' });
                      }}
                    />
                  )}
                </Field>
                <Field name="esiID" defaultValue="" label="ESI Id">
                  {({ fieldProps }) => (
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      onClick={() => {
                        this.setState({ activeSearch: 'esi' });
                      }}
                    />
                  )}
                </Field>
                <Field name="userID" defaultValue="" label="User Id">
                  {({ fieldProps }) => (
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      onClick={() => {
                        this.setState({ activeSearch: 'user' });
                      }}
                    />
                  )}
                </Field>
                <ActionButton type="submit" appearance="primary">
                  Search
                </ActionButton>
              </form>
            )}
          </Form>
          <ul>
            {fetching && <Spinner size="medium" />}
            {results.map(m => {
              return (
                <li key={m.memberID}>
                  <Link to={`/member/${m.memberID}`}>
                    {m.first_name} {m.last_name}:{' '}
                    {pathOr(
                      'no address available',
                      ['service_address', 'lineOne'],
                      m.meters[0]
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Container>
      </Page>
    );
  }
}

export default Search;
