import * as storage from '../utils/storage';
import request from './request';

export const isDev = !window.location.href.includes('app.gogriddy');
export const API_HOST = isDev ? 'https://development.gogriddy.com/api/v1' : 'https://app.gogriddy.com/api/v1' // prettier-ignore

export const api = async (endpoint, options, restricted) => {
  const url = `${API_HOST}${endpoint}`;
  // let url = `${API_HOST}${endpoint}`;
  // if (endpoint.includes('/meter')) {
  //   url = `http://localhost:5000${endpoint}`
  // }

  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  if (restricted) {
    headers.append('Authorization', `Bearer ${storage.getAccessToken()}`);
  }

  try {
    return await request(url, { ...options, headers });
  } catch (err) {
    if (err.code !== 16 || err.message !== 'token has expired') {
      return Promise.reject(err);
    }

    const { access_token: accessToken } = await request(
      `${API_HOST}/users/token/refresh`,
      {
        method: 'POST',
        body: JSON.stringify({ token: storage.getRefreshToken() }),
      }
    );

    storage.setAccessToken(accessToken);
    headers.set('Authorization', `Bearer ${accessToken}`);

    return request(url, { ...options, headers });
  }
};

/* prettier-ignore */
api.post = (endpoint, restricted) => body => api(endpoint, { method: 'POST', body: JSON.stringify(body) }, restricted)

export const login = ({ email, password }) =>
  api.post('/users/signin')({ email, password });
export const searchMembersAll = userID =>
  api.post('/members/get_all', true)({ userID });
export const searchMembers = memberId =>
  api.post('/members/get', true)({ memberID: memberId });
export const getMeter = opts => api.post('/meter/get_meter', true)(opts);
export const getMember = opts => api.post('/members/get', true)(opts);
export const getTdspInvoices = opts =>
  api.post('/billing/tdsp_invoices_report', true)(opts);
export const getPrices = opts => api.post('/insights/getnow', true)(opts);
export const fetchAllPromos = api.post('/promo/get_all', true);
export const fetchPromo = opts => api.post('/promo/get', true)(opts);

export const dnpRequest = opts =>
  api.post('/meter/disconnect_non_pay', true)(opts);
export const rnpRequest = opts =>
  api.post('/meter/reconnect_non_pay', true)(opts);
export const getPendintTransaction = opts =>
  api.post('/meter/get_pending_transactions', true)(opts);
