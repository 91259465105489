import { observable, action, flow, configure, computed } from 'mobx';
import { parseApiList } from 'utils/parse';
import dayjs from 'dayjs';
import messageStore from './messageStore';
import * as API from '../api';

configure({ enforceActions: 'observed' });

class dnpRnpStore {
  @observable pendingTransactions = [];
  @observable loading = false;
  @observable isMemberSearch = false;

  @action.bound clearResults() {
    this.pendingList = [];
  }

  @action.bound clearSearch() {
    this.isMemberSearch = false;
  }

  @computed get memberTableData() {
    const head = {
      cells: [
        {
          isSortable: true,
          key: 'status',
          content: 'Status',
        },
        {
          key: 'originalTransID',
          content: 'Original Trans ID',
        },
        {
          key: 'disconnectRegistered',
          content: 'Disconnect Registered',
        },
        {
          key: 'reconnectRegistered',
          content: 'Reconnect Registered',
        },
      ],
    };

    const rows = this.pendingTransactions.map(t => ({
      cells: [
        {
          key: 'status',
          content: t.status,
        },
        {
          key: 'originalTransID',
          content: t.originalTransID,
        },
        {
          key: 'disconnectRegistered',
          content: `${
            t.disconnectRegistered
              ? dayjs(t.disconnectRegistered).format('MMM, D, YY h:mm a')
              : ''
          }`,
        },
        {
          key: 'reconnectRegistered',
          content: `${
            t.reconnectRegistered
              ? dayjs(t.reconnectRegistered).format('MMM, D, YY h:mm a')
              : ''
          }`,
        },
      ],
    }));

    return { head, rows };
  }

  @computed get tableData() {
    const head = {
      cells: [
        {
          isSortable: true,
          key: 'member',
          content: 'Member ID',
        },
        {
          isSortable: true,
          key: 'meter',
          content: 'esi ID',
        },
        {
          isSortable: true,
          key: 'status',
          content: 'Status',
        },
        {
          key: 'originalTransID',
          content: 'Original Trans ID',
        },
        {
          key: 'disconnectRegistered',
          content: 'Disconnect Registered',
        },
        {
          key: 'reconnectRegistered',
          content: 'Reconnect Registered',
        },
      ],
    };

    const rows = this.pendingTransactions.map(t => ({
      cells: [
        {
          key: 'member',
          content: t.memberID,
        },
        {
          key: 'esi ID',
          content: t.esiID,
        },
        {
          key: 'status',
          content: t.status,
        },
        {
          key: 'originalTransID',
          content: t.originalTransID,
        },
        {
          key: 'disconnectRegistered',
          content: `${
            t.disconnectRegistered
              ? dayjs(t.disconnectRegistered).format('MMM, D, YY h:mm a')
              : ''
          }`,
        },
        {
          key: 'reconnectRegistered',
          content: `${
            t.reconnectRegistered
              ? dayjs(t.reconnectRegistered).format('MMM, D, YY h:mm a')
              : ''
          }`,
        },
      ],
    }));

    return { head, rows };
  }

  dnpRequest = flow(function*(meter_recordID) {
    try {
      yield API.dnpRequest({ meter_recordID });
      this.loading = true;
      yield this.search(meter_recordID);
    } catch (err) {
      this.loading = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);

  rnpRequest = flow(function*(meter_recordID) {
    try {
      yield API.rnpRequest({ meter_recordID });
      this.search(meter_recordID);
    } catch (err) {
      this.loading = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);

  search = flow(function* search(meter_recordID) {
    this.loading = true;
    this.isMemberSearch = true;
    try {
      const { transactions } = yield API.getPendintTransaction({
        meter_recordID,
      });
      const list = parseApiList(transactions);
      this.pendingTransactions = list;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);

  fetchPendingTransactions = flow(function*() {
    this.loading = true;
    try {
      const { transactions } = yield API.getPendintTransaction();
      const list = parseApiList(transactions);
      this.pendingTransactions = list;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      messageStore.newMessage({ type: 'Error', body: err.message });
    }
  }).bind(this);
}

export default new dnpRnpStore();
