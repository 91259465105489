import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Page from 'components/Page';

@inject('pricesStore')
@observer
class Prices extends Component {
  componentDidMount() {
    this.props.pricesStore.getPrices();
  }

  render() {
    const {
      pricesStore: { now, forecast },
    } = this.props;
    return (
      <Page>
        <div>Prices</div>
        <div>Now: {now.price_display}</div>
        <div>Forcast</div>
        <ul>
          {forecast &&
            forecast.map(f => (
              <li key={f.date}>
                {f.settlement_point}: {f.price_display} at {f.date}
              </li>
            ))}
        </ul>
      </Page>
    );
  }
}

export default Prices;
