import { observable, flow, configure } from 'mobx';
import * as API from '../api';

configure({ enforceActions: 'observed' });

class pricesStore {
  @observable now = {};
  @observable forecast = [];

  getPrices = flow(function*() {
    try {
      const date = new Date().toISOString();
      const opts = {
        settlement_point: 'LZ_HOUSTON',
        date,
      };
      const { now, forecast } = yield API.getPrices(opts);
      this.now = now;
      this.forecast = forecast;
    } catch (err) {
      console.log(err);
    }
  }).bind(this);
}

export default new pricesStore();
